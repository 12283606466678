<template>
	<keep-alive>
		<component
			:is="selectedComponent"
			:selectedComponent="selectedComponent"
			:newCourseName="newCourseName"
			:newCourseDescription="newCourseDescription"
			:newCourseMainImage="newCourseMainImage"
			:newCourseType="newCourseType"
			:template="template"
			:message="message"
			:templateFile="templateFile"
			@selectComponent="selectComponent"
			@selectTemplate="selectTemplate"
			@setValue="setValue"
			@createCourse="createCourse"
			@setMessage="setMessage"
		>
		</component>
	</keep-alive>
</template>
<script>
import { post } from "@/util/requests/post";
import { mapGetters } from "vuex";
import NewCourseStep1 from "./NewCourseStep1.vue";
import NewCourseStep2 from "./NewCourseStep2.vue";

export default {
	name: "NewCourse",
	components: {
		NewCourseStep1,
		NewCourseStep2
	},
	computed: {
		...mapGetters(["courseTypes", "organization"])
	},
	data() {
		return {
			selectedComponent: "NewCourseStep1",
			newCourseName: "",
			newCourseDescription: "",
			newCourseMainImage: null,
			newCourseType: "",
			template: null,
			message: "",
			templateFile: null
		};
	},
	methods: {
		setValue({ key, value }) {
			this[key] = value;
		},
		selectComponent(cmp) {
			this.selectedComponent = cmp;
		},
		selectTemplate(template) {
			this.template = template;
		},
		setMessage(val) {
			this.message = val;
		},
		validate() {
			return (
				!!this.newCourseName && !!this.newCourseType && !!this.template
			);
		},
		async createCourse() {
			this.setMessage("");
			if (!this.validate()) {
				this.setMessage("Select template");
				return;
			}
			try {
				await this.$store.dispatch("setIsLoading", true);
				const response = await post("/course", {
					user_id: "1",
					organization_id: this.organization.id,
					course_type_id: this.newCourseType,
					title: this.newCourseName,
					description: this.newCourseDescription,
					duration: null,
					category: null,
					custom: false,
					parent_course_id: null,
					locked_by_parent: false,
					category_id: null
				});
				if (response.status === 201) {
					if (!!this.newCourseMainImage !== false) {
						await post(
							"/course_image",
							{
								course_id: response.data.data.id,
								file: this.newCourseMainImage,
								comment: "comment"
							},
							{
								headers: {
									"Content-Type": "multipart/form-data"
								}
							}
						);
					}

					const chapterResponse = await post("/chapter", {
						course_id: response.data.data.id,
						title: "New Chapter",
						content: "",
						sort_order: 0,
						custom: 1,
						category_id: 1
					});

					if (chapterResponse.status === 201) {
						await post("/page", {
							chapter_id: chapterResponse.data.data.id,
							title: "New page",
							content: "",
							sort_order: 0
						});
					}

					this.$store.dispatch("getCourses");
					this.$router.push({ name: "Courses" });
				}
			} catch (error) {
				this.message = error.response.data.message;
			}
			await this.$store.dispatch("setIsLoading", false);
		}
	}
};
</script>

<style></style>
